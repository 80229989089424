<template>
  <footer>
    <div class="footer-sections">
      <div class="footer-social-network-column">
        <a
          href="https://www.youtube.com/channel/UCN40KaSYFoDMcxL1xzSSA5w?sub_confirmation=1"
          class="img-link yt"
        >
          <img
            src="~/assets/imgs/yt.svg"
            alt="youtube"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
        <a
          href="https://www.linkedin.com/company/horiz-io/"
          class="img-link"
        >
          <img
            src="~/assets/imgs/linkedin.svg"
            alt="linkedin"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
        <a
          href="https://www.instagram.com/horiz.io/"
          class="img-link"
        >
          <img
            src="~/assets/imgs/in.svg"
            alt="instagram"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
        <a
          href="https://www.tiktok.com/@horiz.io"
          class="img-link"
        >
          <img
            src="~/assets/imgs/tiktok.svg"
            alt="tiktok"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
        <a
          href="https://twitter.com/horiz_io "
          class="img-link"
        >
          <img
            src="~/assets/imgs/tw.svg"
            alt="twitter"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
        <a
          href="https://www.facebook.com/horizgenie "
          class="img-link"
        >
          <img
            src="~/assets/imgs/fb.svg"
            alt="facebook"
            width="26"
            height="26"
            loading="lazy"
          >
        </a>
      </div>
      <div
        v-for="section in footer?.sections"
        :key="section.id"
        class="footer-text-column"
      >
        <span class="title">{{ section.title }}</span>
        <cms-components-shared-link
          v-for="link in section.links"
          :key="link.id"
          type="no-style"
          :link="link"
          class="link"
        />
      </div>
    </div>
    <div class="copyright">
      Copyright © {{ currentYear }} Horiz.io
    </div>
    <div
      class="scrolltop-button hide-on-desktop flex-center"
      @click="scrollTop"
    >
      <img
        src="~/assets/imgs/arrow-up.svg"
        alt="bouton retour en haut de page"
        width="28"
        height="32"
        loading="lazy"
      >
    </div>
  </footer>
</template>

<script>
export default {
  props: {
    footer: { type: Object, default: () => ({ sections: [] }) },
  },
  computed: {
    currentYear() {
      return new Date().getFullYear()
    },
  },
  methods: {
    scrollTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>

<style lang="less" scoped>
footer {
  background-color: var(--ds-color-primary-100);
  padding: 40px 20px;
  position: relative;

  .footer-sections {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 24px;
    max-width: 1240px;
    margin: auto;
  }

  .footer-social-network-column {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    place-items: center;
  }

  .footer-text-column {
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: white;

    .title {
      font-size: 20px;
      font-weight: var(--ds-weight-semi-bold);
    }

    .link {
      font-size: 14px;
      color: inherit !important;
    }
  }

  .copyright {
    padding: 40px 0 0;
    text-align: center;
    color: white;
    font-size: 14px;
  }

  @media @bp-md-down {
    .footer-sections {
      grid-template-columns: repeat(auto-fit, minmax(210px, 1fr));
    }

    .footer-social-network-column {
      order: 2;
      grid-column: ~'1 / -1';
      flex-direction: row;
      gap: 10%;
      justify-content: center;
    }
  }

  @media @bp-sm-down {
    .footer-sections {
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }
  }

  // BUTTONS

  .scrolltop-button {
    width: 52px;
    height: 51px;
    bottom: 24px;
    right: 24px;
    padding-top: 4px;
    background: var(--ds-color-primary-100);
    border: 3px solid #ffffff;
  }

  .scrolltop-button {
    position: absolute;
    border-radius: 16px;
    box-shadow: 0 8px 8px rgba(0, 0, 0, 0.13);
  }
}
</style>
